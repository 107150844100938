import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d9e846d = () => interopDefault(import('../src/pages/error.vue' /* webpackChunkName: "pages/error" */))
const _2cbaa3fe = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _be35e678 = () => interopDefault(import('../src/pages/lazada.vue' /* webpackChunkName: "pages/lazada" */))
const _514bf429 = () => interopDefault(import('../src/pages/lich-su-tich-diem-doi-qua/index.vue' /* webpackChunkName: "pages/lich-su-tich-diem-doi-qua/index" */))
const _7dbc737e = () => interopDefault(import('../src/pages/ma-giam-gia/index.vue' /* webpackChunkName: "pages/ma-giam-gia/index" */))
const _40651144 = () => interopDefault(import('../src/pages/shopee/index.vue' /* webpackChunkName: "pages/shopee/index" */))
const _a1fe00d6 = () => interopDefault(import('../src/pages/tai-app.vue' /* webpackChunkName: "pages/tai-app" */))
const _53c6cd89 = () => interopDefault(import('../src/pages/tich-diem-doi-qua/index.vue' /* webpackChunkName: "pages/tich-diem-doi-qua/index" */))
const _755e1251 = () => interopDefault(import('../src/pages/tin-khuyen-mai.vue' /* webpackChunkName: "pages/tin-khuyen-mai" */))
const _77add814 = () => interopDefault(import('../src/pages/tin-khuyen-mai/index.vue' /* webpackChunkName: "pages/tin-khuyen-mai/index" */))
const _75f736cc = () => interopDefault(import('../src/pages/tin-khuyen-mai/_slug.vue' /* webpackChunkName: "pages/tin-khuyen-mai/_slug" */))
const _2b0402b6 = () => interopDefault(import('../src/pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _0c3861a8 = () => interopDefault(import('../src/pages/ma-giam-gia/_pageSlug.vue' /* webpackChunkName: "pages/ma-giam-gia/_pageSlug" */))
const _2af884f0 = () => interopDefault(import('../src/pages/tich-diem-doi-qua/_pageSlug.vue' /* webpackChunkName: "pages/tich-diem-doi-qua/_pageSlug" */))
const _2c4f3392 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2fbc7622 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _2d9e846d,
    name: "error"
  }, {
    path: "/faq",
    component: _2cbaa3fe,
    name: "faq"
  }, {
    path: "/lazada",
    component: _be35e678,
    name: "lazada"
  }, {
    path: "/lich-su-tich-diem-doi-qua",
    component: _514bf429,
    name: "lich-su-tich-diem-doi-qua"
  }, {
    path: "/ma-giam-gia",
    component: _7dbc737e,
    name: "ma-giam-gia"
  }, {
    path: "/shopee",
    component: _40651144,
    name: "shopee"
  }, {
    path: "/tai-app",
    component: _a1fe00d6,
    name: "tai-app"
  }, {
    path: "/tich-diem-doi-qua",
    component: _53c6cd89,
    name: "tich-diem-doi-qua"
  }, {
    path: "/tin-khuyen-mai",
    component: _755e1251,
    children: [{
      path: "",
      component: _77add814,
      name: "tin-khuyen-mai"
    }, {
      path: ":slug",
      component: _75f736cc,
      name: "tin-khuyen-mai-slug"
    }]
  }, {
    path: "/faq/:slug",
    component: _2b0402b6,
    name: "faq-slug"
  }, {
    path: "/ma-giam-gia/:pageSlug?",
    component: _0c3861a8,
    name: "ma-giam-gia-pageSlug"
  }, {
    path: "/tich-diem-doi-qua/:pageSlug?",
    component: _2af884f0,
    name: "tich-diem-doi-qua-pageSlug"
  }, {
    path: "/",
    component: _2c4f3392,
    name: "index"
  }, {
    path: "/:slug",
    component: _2fbc7622,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
