<template>
    <div>
        <div
            class="hidden lg:flex lg:top-0 bg-white shadow-md w-screen fixed justify-between"
            style="z-index: 1110;height: 80px; box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 1%), 0 2px 4px -1px rgb(0 0 0 / 9%)"
        >
            <div class="max-w-1200 m-auto flex flex-col justify-center lg:px-5 w-screen h-full">
                <div class="flex justify-between w-full">
                    <div>
                        <nuxt-link to="/">
                            <nuxt-img
                                src="/images/logo.svg"
                                alt="logo"
                                style="width:150px; height:42px"
                            />
                        </nuxt-link>
                    </div>
                    <div class="flex items-center text-base">
                        <div v-for="(item, index) in navItems" :key="index" class="mx-3 leading-10">
                            <nuxt-link
                                :to="item?.link"
                                :class="$style['nav-item']"
                            >
                                {{ item.label }}
                            </nuxt-link>
                        </div>
                        <div class="mx-3 leading-10 cursor-pointer" :class="$style['nav-item']" @click="handlePopupLoyalty">
                            Tích điểm đổi quà
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        components: {
        },

        data: () => ({
            navItems: [{
                           label: 'Trang chủ',
                           link: '/',
                       },
                       {
                           label: 'Mã giảm giá',
                           link: '/ma-giam-gia',
                           childs: [
                               {
                                   label: 'Shopee',
                                   link: '/shopee',
                               },
                               {
                                   label: 'Lazada',
                                   link: '/ma-giam-gia/lazada',
                               },
                               {
                                   label: 'Tiki',
                                   link: '/ma-giam-gia/tiki',
                               },
                           ],
                       },
                       {
                           label: 'Tin khuyến mãi',
                           link: '/tin-khuyen-mai',
                       },
                       {
                           label: 'Trung tâm hỗ trợ',
                           link: '/faq',
                       },
            ],
            registerVisible: false,
            dialogVisible: false,
        }),

        computed: {
            ...mapState('authForm', ['statusFormLogin', 'statusFormRegister']),
        },

        watch: {
            statusFormRegister(val) {
                if (val) {
                    this.$refs.registerDialog.open();
                }
            },
            statusFormLogin(val) {
                if (val) {
                    this.$refs.loginDialog.open();
                }
            },
        },

        methods: {
            ...mapActions('authForm', ['setStatusFormLogin', 'setStatusFormRegister']),
            handleCommand(command) {
                if (command === 'logout') {
                    this.$auth.logout();
                    this.$auth.setUser(null);
                    this.$auth.setUserToken(null);
                    this.$message.success('Đăng xuất thành công');
                }
                if (command === 'productSaved') {
                    this.$router.push('/danh-sach-san-pham');
                }
                if (command === 'infor') {
                    this.$router.push('/tai-khoan');
                }
                if (command === 'voucherSaved') {
                    this.$router.push('/voucher-da-luu');
                }
            },
            handlePopupLoyalty() {
                this.$emit('isPopupNotificationMobile', true);
            },
        },
    };
</script>

<style lang="scss" module>
    .nav-item::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: #008A07;
        transition: width .3s;
    }

    .nav-item:hover::after {
        width: 100%;
    }

    .register-btn {
        @apply rounded-xl;
    }
</style>
